import Content from '#components/shared/ui/Content';
import Typography from '#components/shared/ui/Typography';
import USA from '#components/shared/ui/USA';
import { STATES } from '#constants/states.ts';
import { getRelativeLocaleUrl, type WithTranslations } from '#i18n/index.ts';
import { scrollToTop } from '#utils/scroll.ts';
import { navigate } from 'astro:transitions/client';
import { type FC, useState } from 'react';
import styles from './LookupFind.module.scss';

type Props = {
  locale?: string;
  currentUrl: URL;
};

const LookupFind: FC<WithTranslations<Props>> = ({ t, locale, currentUrl }) => {
  const [activeState, setActiveState] = useState<string | undefined>(undefined);
  const preparedRedirectPath = 'address-lookup';
  const stateClickHandler = () => {
    // TODO: store routes somewhere in enum or map
    if (currentUrl.pathname.includes('address-lookup')) {
      return scrollToTop();
    }
    void navigate(getRelativeLocaleUrl(locale, preparedRedirectPath));
  };

  return (
    <Content classes={styles.content}>
      <div className={styles.container}>
        <div className={styles.texts}>
          <Typography component="h2" variant="h3" color="branded" className={styles.text}>
            {t.lookups.shared.findTitle1}
            <br />
            <Typography component="span" variant="h3" color="primary">
              {t.lookups.shared.findTitle2}
            </Typography>
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            color="secondary"
            className={`${styles.text} ${styles.subtitle}`}
          >
            {t.lookups.shared.findSubtitle}
          </Typography>
          <Typography component="p" variant="body1" color="secondary" className={styles.text}>
            {t.lookups.shared.findDescription}
          </Typography>
          <div className={styles.states}>
            {STATES.map((state) => (
              <Typography
                key={state}
                component="span"
                variant="subtitle1"
                color="secondary"
                className={`${styles.state} ${state === activeState ? styles.active : ''}`}
                onMouseEnter={() => setActiveState(state)}
                onMouseLeave={() => setActiveState(undefined)}
                onClick={stateClickHandler}
              >
                {state}
              </Typography>
            ))}
          </div>
        </div>
        <USA
          currentState={activeState}
          onStateMouseLeave={() => setActiveState(undefined)}
          onStateMouseEnter={(e) => setActiveState(e.currentTarget.id)}
          onStateClick={stateClickHandler}
        />
      </div>
    </Content>
  );
};

export default LookupFind;
