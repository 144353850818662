import HelpIcon from '#components/shared/ui/Icon/Help.tsx';
import styles from './ManualChat.module.scss';
import { type FC, useState } from 'react';
import QuestionIcon from '#components/shared/ui/Icon/QuestionIcon.tsx';

type Props = {
  isPlacedInHeader?: boolean;
};

const ManualChat: FC<Props> = ({ isPlacedInHeader = true }: Props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const openZendeskChat = async () => {
    setLoaded(true);
    const { default: zendesk } = await import('#utils/zendesk.ts');
    zendesk.init({ openChatOnMount: true });
  };

  if (loaded) return '';

  return isPlacedInHeader ? (
    <div className={styles.headerChatIcon} onClick={openZendeskChat}>
      <QuestionIcon />
    </div>
  ) : (
    <div className={styles.helpBtn} onClick={openZendeskChat}>
      <HelpIcon />
    </div>
  );
};

export default ManualChat;
