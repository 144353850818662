const QuestionIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 11C21 16.5228 16.5228 21 11 21C9.51298 21 8.10176 20.6754 6.83333 20.0933C6.4117 19.8997 6.00584 19.6778 5.61822 19.4298L1 20.5833L2.25 15.845C2.0544 15.4925 1.87949 15.127 1.7269 14.75C1.25813 13.592 1 12.3261 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke="#14B8B3"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 8.91504C8.5 7.53433 9.61929 6.41504 11 6.41504C12.3807 6.41504 13.5 7.53433 13.5 8.91504C13.5 9.86141 12.9742 10.685 12.1987 11.1094C11.5932 11.4409 11 11.9747 11 12.665V12.8738M11 15.8317V15.8187"
        stroke="#14B8B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuestionIcon;
