import Autocomplete from '#components/shared/ui/Autocomplete';
import Button from '#components/shared/ui/Button';
import Form from '#components/shared/ui/Form';
import Input from '#components/shared/ui/Input';
import { STATES_FULL_NAMES, STATES_NAMES_CODES_MAP } from '#constants/states.ts';
import schema from '#schemas/backgroundCheck.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import analytics from '#utils/analytics.ts';
import { urlBuilder } from '#utils/urlBuilder.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import type { BaseSyntheticEvent, FC } from 'react';
import { Controller, type FieldErrors, useForm } from 'react-hook-form';
import styles from './FeatureHeroFormBackgroundCheck.module.scss';

type FormValues = {
  first_name: string;
  last_name: string;
  state?: string;
};

const FeatureHeroFormBackgroundCheck: FC<FeatureHeroCommonProps> = ({
  t,
  isNaft,
  onSubmitAction,
}) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { first_name: '', last_name: '', state: '' },
  });
  const { handleSubmit, formState, register, setValue } = form;

  const onSubmit = (values: FormValues) => {
    const basePath = isNaft ? 'searching-naft' : 'searching';
    analytics.event({ action: 'search_background' });
    const url = urlBuilder(
      {
        ...values,
        state: STATES_NAMES_CODES_MAP[values.state ?? 'All'],
      },
      basePath
    );

    if (onSubmitAction) onSubmitAction();

    window.location.assign(url);
  };

  const onFail = (errors: FieldErrors<FormValues>, e?: BaseSyntheticEvent) => {
    if (errors.state) setValue('state', '');
  };

  return (
    <Form className={styles.form} form={form} onSubmit={handleSubmit(onSubmit, onFail)}>
      <Input
        {...register('first_name')}
        placeholder="First Name"
        wrapperClassName={styles.item}
        error={formState.errors.first_name?.message}
      />
      <Input
        {...register('last_name')}
        placeholder="Last Name"
        wrapperClassName={styles.item}
        error={formState.errors.last_name?.message}
      />
      <Controller
        name="state"
        control={form.control}
        render={({ field: { ref, value, onChange, name }, formState }) => (
          <Autocomplete
            name={name}
            ref={ref}
            value={value ?? ''}
            placeholder="State"
            className={styles.item}
            data={STATES_FULL_NAMES}
            error={formState.errors.state?.message}
            onValueChanged={(value) => onChange(value)}
            showOptionsOnEmpty
          />
        )}
      />
      <Button variant="accent" size="lg" className={styles.button}>
        {t.lookups.backgroundCheck.action}
      </Button>
    </Form>
  );
};

export default FeatureHeroFormBackgroundCheck;
