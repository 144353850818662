import Typography from '#components/shared/ui/Typography';
import { POSTFIXED_FEATURES } from '#constants/features.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import type { Feature } from '#types/FeatureHeroTypes';
import { getSearchParams } from '#utils/getSearchParams.ts';
import clsx from 'clsx';
import { type FC, useEffect, useState } from 'react';
import styles from './FeatureHeroTitleData.module.scss';

type Props = {
  feature: Feature;
  subtitle?: string;
};

const FeatureHeroTitleData: FC<FeatureHeroCommonProps<Props>> = ({ t, subtitle, feature }) => {
  const lookups = t.lookups[feature as keyof typeof t.lookups];
  const defaultTitle = lookups[String('title') as keyof typeof lookups];
  const defaultDescription = lookups[String('description') as keyof typeof lookups];

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const isOffendersPage = feature === 'offenders';
  const isVinPage = feature === 'vin';
  const isBackgroundPage = feature === 'backgroundCheck';
  const isPhoneSearchFeature = ['phoneSearch1', 'phoneSearch'].includes(feature);
  const isNonStandardPage =
    isOffendersPage || isVinPage || isBackgroundPage || isPhoneSearchFeature;

  useEffect(() => {
    const titleText = getSearchParams('ptid');
    const descriptionText = getSearchParams('pdid');

    if (titleText) setTitle(titleText);
    if (descriptionText) setDescription(descriptionText);
  }, []);

  return (
    <>
      <Typography
        className={clsx(styles.title, isOffendersPage && styles.titleOffender)}
        style={isNonStandardPage ? { textAlign: 'center' } : undefined}
        component="h1"
        variant="h2"
        color="branded"
      >
        {title || defaultTitle}
      </Typography>
      {!title && POSTFIXED_FEATURES.includes(feature) && (
        <Typography
          className={clsx(styles.title, styles.hide, styles.dc)}
          component="h1"
          variant="h2"
          color="branded"
        >
          {t.lookups.subTitle}
        </Typography>
      )}
      {subtitle && (
        <Typography
          className={clsx(styles.subtitle, styles.dc)}
          component="h2"
          variant="h4"
          color="secondary"
        >
          {subtitle}
        </Typography>
      )}
      {!isBackgroundPage && isNonStandardPage && (
        <Typography
          className={clsx(
            styles.description,
            isOffendersPage && styles.descriptionOffender,
            isPhoneSearchFeature && styles.descriptionPhoneSearch
          )}
          component="p"
          variant="body1"
          color="secondary"
        >
          {description || defaultDescription}
        </Typography>
      )}
    </>
  );
};

export default FeatureHeroTitleData;
