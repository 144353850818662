import Button from '#components/shared/ui/Button';
import Form from '#components/shared/ui/Form';
import Input from '#components/shared/ui/Input';
import { COMPLIANCE_HOSTS } from '#constants/compliances.ts';
import schema from '#schemas/phone.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import { FeatureHeroTypes } from '#types/FeatureHeroTypes.ts';
import analytics from '#utils/analytics.ts';
import { urlBuilder } from '#utils/urlBuilder.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { HOST } from 'CONFIG';
import parsePhoneNumber from 'libphonenumber-js';
import { type ChangeEvent, type FC } from 'react';
import { useForm } from 'react-hook-form';
import styles from './FeatureHeroFormPhone.module.scss';

const isCompliance = COMPLIANCE_HOSTS.includes(HOST);

type FormValues = {
  phone: string;
};

const FeatureHeroFormPhone: FC<FeatureHeroCommonProps> = ({
  t,
  feature,
  isPeopleSearch,
  onSubmitAction,
}) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { phone: '' },
  });
  const { handleSubmit, formState, register } = form;
  const isPhoneLookup = feature === FeatureHeroTypes.phoneLookup;
  const isPhoneSearch = feature === FeatureHeroTypes.phoneSearch;
  const isPhoneSearch1 = feature === FeatureHeroTypes.phoneSearch1;
  const isMobiPhoneLookup = feature === FeatureHeroTypes.mobiPhoneLookup;
  const placeholder = {
    phoneSearch: t.lookups.phoneSearch.placeholder,
    phoneSearch1: t.lookups.phoneSearch1.placeholder,
    phone: t.lookups.phone.placeholder,
    mobiPhoneLookup: t.lookups.mobiPhoneLookup.hero.inputPlaceholder,
  };
  const isPhoneSearchPages = isPhoneSearch || isPhoneSearch1;

  const buttonPlaceholder = {
    mobiPhoneLookup: t.lookups.mobiPhoneLookup.hero.buttonPlaceholder,
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9()+\-\s]/g, '');
    form.setValue('phone', value);
  };

  const onSubmit = (values: FormValues) => {
    analytics.event({
      action: 'search_phone',
    });

    const phoneNumber = parsePhoneNumber(values.phone, 'US'); // US phone number parser by default (temporary)
    const { nationalNumber } = phoneNumber || {};

    const phone = nationalNumber ?? values.phone;

    if (onSubmitAction) onSubmitAction();

    if (isPhoneSearchPages || isMobiPhoneLookup) {
      localStorage.setItem(
        'customerSearch',
        JSON.stringify({
          type: 'phone',
          value: {
            phone,
          },
          search: phone,
          searchDate: Date.now(),
        })
      );
      if (isPhoneSearch1) window.location.assign('/phone-search-purchase');
      else if (isMobiPhoneLookup) {
        const url = urlBuilder({ phone }, '/phone-search-purchase');
        window.location.assign(url);
      } else window.location.assign('/checkout-new');
      return;
    }

    const url = urlBuilder({ phone }, isPhoneLookup && !isCompliance ? '/spf' : undefined);
    window.location.assign(url);
  };

  return (
    <Form
      className={clsx(styles.form, !isPhoneSearchPages && styles.dc, {
        [styles.peopleSearch]: isPeopleSearch,
        [styles.phoneSearchPages]: isPhoneSearchPages,
      })}
      form={form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        {...register('phone')}
        placeholder={placeholder[feature as keyof typeof placeholder]}
        wrapperClassName={styles.input}
        error={formState.errors.phone?.message}
        onChange={onChange}
        type="tel"
        data-search
        id="phone-input-field"
        label={isMobiPhoneLookup ? 'Phone' : undefined}
      />
      <Button
        variant="accent"
        size="lg"
        className={clsx(
          styles.button,
          isPeopleSearch && styles.peopleSearch,
          isPhoneSearchPages && styles.phoneSearchBtn
        )}
      >
        {buttonPlaceholder[feature as keyof typeof buttonPlaceholder] ?? t.lookups.phone.action}
      </Button>
    </Form>
  );
};

export default FeatureHeroFormPhone;
