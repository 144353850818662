import type { FC } from 'react';
import styles from './FeatureHeroFormContainer.module.scss';
import FeatureHeroFormName from '../FeatureHeroFormName';
import FeatureHeroFormEmail from '../FeatureHeroFormEmail';
import FeatureHeroFormPhone from '../FeatureHeroFormPhone';
import FeatureHeroFormAddress from '../FeatureHeroFormAddress';
import FeatureHeroFormBackgroundCheck from '../FeatureHeroFormBackgroundCheck';
import FeatureHeroFormVin from '../FeatureHeroFormVin';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import { FeatureHeroTypes } from '#types/FeatureHeroTypes.ts';
import FeatureHeroFormOffenders from '#components/shared/sections/FeatureHero/FeatureHeroFormOffenders';

type Props = {
  feature: string;
};

const FeatureHeroFormContainer: FC<FeatureHeroCommonProps<Props>> = ({ feature, ...props }) => {
  const renderLookupForm = () => {
    switch (feature) {
      case 'name':
        return <FeatureHeroFormName {...props} />;
      case 'email':
        return <FeatureHeroFormEmail {...props} />;
      case 'phone':
        return <FeatureHeroFormPhone {...props} feature={FeatureHeroTypes.phoneLookup} />;
      case 'address':
        return <FeatureHeroFormAddress {...props} />;
      case 'backgroundCheck':
        return <FeatureHeroFormBackgroundCheck {...props} />;
      case 'vin':
        return <FeatureHeroFormVin {...props} />;
      case 'phoneSearch':
        return <FeatureHeroFormPhone {...props} feature={FeatureHeroTypes.phoneSearch} />;
      case 'phoneSearch1':
        return <FeatureHeroFormPhone {...props} feature={FeatureHeroTypes.phoneSearch1} />;
      case 'offenders':
        return <FeatureHeroFormOffenders {...props} feature={FeatureHeroTypes.phoneSearch1} />;
      case 'mobiPhoneLookup':
        return <FeatureHeroFormPhone {...props} feature={FeatureHeroTypes.mobiPhoneLookup} />;
      default:
        throw new Error('You are trying to render a form for feature which does not exist.');
    }
  };

  return (
    <div className={feature !== 'offenders' ? styles.container : undefined}>
      {renderLookupForm()}
    </div>
  );
};

export default FeatureHeroFormContainer;
