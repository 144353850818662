import Autocomplete from '#components/shared/ui/Autocomplete';
import Button from '#components/shared/ui/Button';
import Form from '#components/shared/ui/Form';
import Input from '#components/shared/ui/Input';
import { STATES_FULL_NAMES, STATES_NAMES_CODES_MAP } from '#constants/states.ts';
import { TEST_PAGE_URL } from '#constants/testPage.ts';
import schema from '#schemas/name.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import analytics from '#utils/analytics.ts';
import { urlBuilder } from '#utils/urlBuilder.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { type BaseSyntheticEvent, type FC } from 'react';
import { Controller, type FieldErrors, useForm } from 'react-hook-form';
import styles from './FeatureHeroFormName.module.scss';

type FormValues = {
  firstName: string;
  lastName: string;
  state?: string;
};

const FeatureHeroFormName: FC<FeatureHeroCommonProps> = ({ t, isPeopleSearch, onSubmitAction }) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { firstName: '', lastName: '', state: '' },
  });
  const { handleSubmit, formState, register, setValue } = form;

  const getBasePath = () => {
    const { pathname } = window.location;
    const paths = {
      [TEST_PAGE_URL.NAME_1]: TEST_PAGE_URL.SEARCHING_NAME_1,
      [TEST_PAGE_URL.NAME_2]: TEST_PAGE_URL.SEARCHING_NAME_2,
      [TEST_PAGE_URL.NAME_3]: TEST_PAGE_URL.SEARCHING_NAME_3,
    };
    return paths[pathname.replace(/\//g, '') as keyof typeof paths];
  };

  const onSubmit = ({ state = 'All', firstName, lastName }: FormValues) => {
    analytics.event({
      action: 'search_name',
      event_params: `first_name_value: ${firstName}, last_name_value: ${lastName}`,
    });

    const values = {
      first_name: firstName,
      last_name: lastName,
      state: STATES_NAMES_CODES_MAP[state],
    };
    const url = urlBuilder({ ...values }, getBasePath());

    if (onSubmitAction) onSubmitAction();
    window.location.assign(url);
  };

  const onFail = (errors: FieldErrors<FormValues>, e?: BaseSyntheticEvent) => {
    if (errors.state) setValue('state', '');
  };

  return (
    <Form
      className={clsx(styles.formWrapper, styles.dc, { [styles.peopleSearch]: isPeopleSearch })}
      form={form}
      onSubmit={handleSubmit(onSubmit, onFail)}
    >
      <div className={clsx(styles.form, styles.dc, { [styles.peopleSearch]: isPeopleSearch })}>
        <Input
          {...register('firstName')}
          placeholder="First Name"
          wrapperClassName={styles.item}
          error={formState.errors.firstName?.message}
        />
        <Input
          {...register('lastName')}
          placeholder="Last Name"
          wrapperClassName={styles.item}
          error={formState.errors.lastName?.message}
        />
        <Controller
          name="state"
          control={form.control}
          render={({ field: { ref, value, onChange, name }, formState }) => (
            <Autocomplete
              name={name}
              ref={ref}
              value={value ?? ''}
              placeholder="State"
              className={styles.item}
              data={STATES_FULL_NAMES}
              error={formState.errors.state?.message}
              onValueChanged={(value) => onChange(value)}
              showOptionsOnEmpty
            />
          )}
        />
      </div>
      <Button variant="accent" size="lg" className={styles.button}>
        {t.lookups.name.action}
      </Button>
    </Form>
  );
};

export default FeatureHeroFormName;
